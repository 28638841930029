import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Pizzas = () => (
  <>
    <SEO title="Pizzas Doces" />

    <Menu className="menu">
      <Slide left cascade duration={300}>
        <h1 className="menu-title">Pizzas Doces</h1>

        <h2 className="menu-info">
          Todas as pizzas doces vão com borda de chocolate
        </h2>
      </Slide>

      <div className="menu-sizes">
        <p>4 Pdç</p>
        <p>8 Pdç</p>
        <p>12 Pdç</p>
      </div>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">36 - Banana Nevada</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">37,00</h4>
            <h4 className="item-price">52,00</h4>
            <h4 className="item-price">66,00</h4>
          </div>
        </div>

        <p className="item-desc">Banana, chocolate branco, canela e açúcar.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">37 - Brigadeiro</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">37,00</h4>
            <h4 className="item-price">52,00</h4>
            <h4 className="item-price">66,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate com granulado.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">38 - Chocobana</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">37,00</h4>
            <h4 className="item-price">52,00</h4>
            <h4 className="item-price">66,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Leite condensado, mussarela, banana e chocolate.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">39 - Confete</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">39,00</h4>
            <h4 className="item-price">54,00</h4>
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate com confete.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">40 - Dois Amores</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">37,00</h4>
            <h4 className="item-price">52,00</h4>
            <h4 className="item-price">66,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate branco e chocolate preto.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">41 - Napolitana</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">39,00</h4>
            <h4 className="item-price">54,00</h4>
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Ninho, creme de morango e chocolate ao leite.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">42 - Leite Ninho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">39,00</h4>
            <h4 className="item-price">54,00</h4>
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate com creme de leite ninho.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">43 - Ouro Branco</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">39,00</h4>
            <h4 className="item-price">54,00</h4>
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate com bombom Ouro Branco</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">44 - Prestígio</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">37,00</h4>
            <h4 className="item-price">52,00</h4>
            <h4 className="item-price">66,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate com coco ralado</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">45 - Morango</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">39,00</h4>
            <h4 className="item-price">54,00</h4>
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate e morango fruta.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            46 - Surpresa de Uva <span>Novidade</span>
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">39,00</h4>
            <h4 className="item-price">54,00</h4>
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Delicioso chocolate ao leite, uvas verdes sem caroço e um maravilhoso
          creme de ninho.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            47 - Abacaxi Nevado <span>Novidade</span>
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">39,00</h4>
            <h4 className="item-price">54,00</h4>
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Delicioso abacaxi com chocolate branco e uma pitada de canela.
        </p>
      </Item>
    </Menu>
  </>
);

export default Pizzas;
